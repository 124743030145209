import gsap, { Power1 } from "gsap";

import { GetBy } from "../_app/cuchillo/core/Element";
import { isDebug } from '../_app/cuchillo/core/Basics';

export default class Preloader {
    _container;
    _progress;

    static init () {
        if(isDebug) GetBy.id('Preloader').style.display = "none";
       

        this._container = GetBy.id('Preloader');
        this._progress = GetBy.class('logo', this._container);

        gsap.to(this._progress, {
            duration: .4,
            opacity: 1,
            delay: 0,
        });
    }

    static update (__progress) {
        if(isDebug) return;
    }

    static hide (__call) {
        if(isDebug) {
            if(__call) __call();
            return;
        }

        gsap.to(this._progress, {
            duration: .3,
            opacity: 0,
            delay: 0,
        });

        gsap.to(this._container, {
            duration: .4,
            opacity: 0,
            delay: 0,
            onComplete: () => {
            this._container.style.display = "none";
            if(__call) __call();
            }
        });
    }
}
